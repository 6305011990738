<template>
  <div class="page_container">
    <section class="row center">
      <div class="pt-35 pb-5 w600 row space-between">
        <h1 class="my-0">Software license pyament review</h1>
        <router-link to="/licenses" class="row align-end gap-10 mb-2">
          <s-icon color="white" height="20">chevron-left</s-icon>
          <div class="my-0">Back</div>
        </router-link>
      </div>
    </section>
    <hr class="mt-15" />
    <section class="center pt-50 pb-20 col">
      <div class="w600 weight-300">Review the details of your order</div>
    </section>
    <section class="w600 mx-auto pb-20">
      <div v-for="item in invoice.items" :key="item.idx" class="grid mb-20">
        <div class="label">License Name</div>
        <div class="item">{{ item.name }}</div>
        <div class="label">Amount</div>
        <div class="item">{{ item.amount }}</div>
        <div class="label">License Amount</div>
        <div class="item">{{ formatCurrency(item.price) }}</div>
      </div>
    </section>
    <section class="w600 mx-auto py-20 col gap-10">
      <button @click="check = !check" class="gap-20 row align-center">
        <s-checkbox v-model="check"></s-checkbox>
        <span>Reoccuring payments?</span>
      </button>
      <span class="font-14 opacity-75">Review the lisence with auto payments after the monthly or Annual period, depending on the type of license chosen.  </span>
    </section>
    <section class="w600 mx-auto py-20">
      <section class="w600 mx-auto mt-20 gap-20 col">
        <div class="weight-300">Choose how you with to pay</div>
        <s-radio class="row" v-model="radio" vertical :options="options.map((el) => el.name)">
          <slot>
            <div class="center pa-2 gap-10 nonwrap">
              <img src="/img/static/powered-by-stripe.png" alt="">
              <img src="/img/static/mastercard-logo.png" alt="">
              <img src="/img/static/visa.png" alt="">
            </div>
          </slot>
        </s-radio>
      </section>
      <div class="row py-20 justify-end gap-10">
        <div class="">Total Price:</div>
        <div class="">{{ formatCurrency(invoice.priceTotal) }}</div>
      </div>
      <section class="w600 mx-auto mt-20 justify-end">
        <s-btn @click.native="route(radio)" class="green">Next</s-btn>
      </section>
    </section>
  </div>
</template>

<script>
import { mapState } from "vuex";
import dayjs from "dayjs";
export default {
  data() {
    return {
      radio: "",
      check: false
    };
  },
  methods: {
    formatCurrency(x) {
      x = Number(x).toFixed(2);
      var parts = x.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return "$ " + parts.join(".");
    },
    format(val) {
      return dayjs(val).format("DD MMM YY");
    },
    sentense(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    getName(id) {
      return this.products.find((el) => el.id == id).name;
    },
    route(val) {
      let op;
      if (val == 0) op = { name: "wire", params: { id: this.id } };
      if (val == 1) op = { name: "stripe", params: { id: this.id } };
      this.$router.push(op);
    },
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    ...mapState("payment", ["options"]),
    ...mapState("invoice", ["invoice"]),
    ...mapState("products", ["products"]),
  },
  watch: {},
  async mounted() {
    this.$store.dispatch("invoice/getInvoiceByID", this.id);
    this.$store.dispatch("payment/getPaymentOptions");
  },
};
</script>

<style lang="less" scoped>
.label {
  background-color: rgba(0, 0, 0, 0.35);
  padding: 8px;
}

.item {
  display: flex;
  align-items: center;
  padding-left: 8px;
  border-bottom: 1px solid #42515e;
  border-right: 1px solid #42515e;
  border-top: 1px solid #42515e;
}

.grid {
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-column-gap: 2px;
  width: 100%;
  transition: 0.2s all ease;
}

img {
  object-fit: contain;
  max-height: 16px;
}
</style>